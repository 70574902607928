import {LitElement, html} from 'lit';
/**
 * `error-page`
 *  Error handling page e.g. browsing to a non existent page
 */
export class ErrorPage extends LitElement {
  /**
   * Defined the elements content
   *
   * @return {TemplateResult} the resulting html template
   */
  render() {
    return html`<h2>Seems you're a bit lost. <a href="/">Go home</a></h2>`;
  }
}
window.customElements.define('error-page', ErrorPage);
